import st from './Assets/strategy.png';
import tick from './Assets/tick.png';
import file from './Assets/file.png';
import ann from './Assets/ann.png';

export default function Services(){

    return(
        <section id="services" style={{ padding: '5% 8%', textAlign:'center' }} className="scroll-wrap-fit">
            <button className="lite" style={{ display:'flex', alignItems:'center', gap:'10px', margin:'auto' }}>  
                <lord-icon
                    src="https://cdn.lordicon.com/hkkhwztk.json"
                    trigger="hover"
                    colors="primary:#ABD4F6"
                    style={{width:'20px', height:'20px'}}>
                </lord-icon>
                Our Services
            </button>

            <h2 style={{color:'#1A457F'}}>
                Your All-in-One Digital Partner!
            </h2>

            <p style={{padding:'1% 15%', fontSize:'medium'}}>
                Are you searching for a professional web development company that can turn your digital dreams into reality? 
                Look no further! Macdasy is here to deliver cutting-edge web solutions tailored to your unique business needs.
            </p>

            <div className="uk-child-width-1-4@m uk-text-left uk-grid-match" data-uk-grid>

                <div>
                    <div className="service-card"  style={{position:"relative", overflow:'hidden'}}>

                            <img    
                                src={st}
                                alt=""
                                style={{ position:'absolute', width:'7em', right:-20, top:-20, zIndex:'-99' }}
                            />

                        <div style={{ padding:'30% 0%' }} />
                        <lord-icon
                            src="https://cdn.lordicon.com/qtldxoay.json"
                            trigger="loop"
                            delay="3000"
                            colors="primary:#ABD4F6"
                            style={{width:'50px', height: '50px'}}>
                        </lord-icon>

                        <p style={{marginBottom:'5%', color:"#1A457F"}}>
                            <strong> Strategic Design & Development </strong>
                        </p>

                        <p style={{marginTop:0, fontSize:'small'}}>
                            Our experts combine aesthetics with functionalities to craft websites 
                            that make a lasting impression on viewer's eyes.
                        </p>
                    </div>
                </div>

                <div>
                    <div className="service-card"  style={{position:"relative", overflow:'hidden'}}>

                            <img    
                                src={file}
                                alt=""
                                style={{ position:'absolute', width:'7em', right:-20, top:-20, zIndex:'-99' }}
                            />
                        
                        <div style={{ padding:'30% 0%' }} />
                        <lord-icon
                             src="https://cdn.lordicon.com/pnwpbzow.json"
                             trigger="loop"
                             delay="4000"
                            colors="primary:#ABD4F6"
                            style={{width:'50px', height: '50px'}}>
                        </lord-icon>

                        <p style={{marginBottom:'5%', color:"#1A457F"}}>
                            <strong> Responsive & User-Friendly </strong>
                        </p>

                        <p style={{marginTop:0, fontSize:'small'}}>
                            Our responsive designs ensure that your sites adapt seamlessly to various 
                            screen sizes, with a consistent and enjoyable user experience.
                        </p>
                    </div>
                </div>

                <div>
                    <div className="service-card"  style={{position:"relative", overflow:'hidden'}}>

                            <img    
                                src={tick}
                                alt=""
                                style={{ position:'absolute', width:'7em', right:-20, top:-20, zIndex:'-99' }}
                            />

                        <div style={{ padding:'30% 0%' }} />
                        <lord-icon
                            src="https://cdn.lordicon.com/icxqolmx.json"
                            trigger="loop"
                            delay="5000"
                            colors="primary:#ABD4F6"
                            style={{width:'50px', height: '50px'}}>
                        </lord-icon>

                        <p style={{marginBottom:'5%', color:"#1A457F"}}>
                            <strong>Custom Objectives & Solutions</strong>
                        </p>

                        <p style={{marginTop:0, fontSize:'small'}}>
                            Our custom web development services allow us to tailor every element to suit 
                            your brand identity, objectives, and target audience.
                        </p>
                    </div>
                </div>

                <div>
                    <div className="service-card"  style={{position:"relative", overflow:'hidden'}}>

                            <img    
                                src={ann}
                                alt=""
                                style={{ position:'absolute', width:'7em', right:-20, top:-20, zIndex:'-99', filter:'blur(0.1em)' }}
                            />

                        <div style={{ padding:'30% 0%' }} />
                        <lord-icon
                            src="https://cdn.lordicon.com/mxzuvjjs.json"
                            trigger="loop"
                            delay="6000"
                            colors="primary:#ABD4F6"
                            style={{width:'50px', height: '50px'}}>
                        </lord-icon>

                        <p style={{marginBottom:'5%', color:"#1A457F"}}>
                            <strong> Super SEO-Optimized </strong>
                        </p>

                        <p style={{marginTop:0, fontSize:'small'}}>
                            Our team employs industry-leading SEO techniques to ensure 
                            that your website ranks high on search engines, driving organic 
                            traffic and boosting your online visibility.
                        </p>
                    </div>
                </div>

            </div>


        </section>
    )

}