
export default function Landing(){

    const scrollKnow = () =>{
        document.querySelector( '#services' ).scrollIntoView({behavior:'smooth'})
    }
    const scroll = () =>{
        document.querySelector( '#contact .lite' ).scrollIntoView({behavior:'smooth'})
    }

    return(
        <section style={{padding:'0% 8% 5%'}}>
            
            {/* <button style={{ display:'flex', alignItems:'center', gap:'10px', maxWidth: 'max-content', borderColor:"#E2E1FC", color:"#E2E1FC" }}>
                <lord-icon
                    src="https://cdn.lordicon.com/zvllgyec.json"
                    trigger="loop"
                    delay="2000"
                    colors="primary:#E2E1FC"
                    style={{width:'20px', height: '20px'}}>
                </lord-icon>
                A Real Game Changer on Web Development
            </button> */}

            <div class="uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-2@l uk-text-center uk-grid-match uk-grid-large" data-uk-grid style={{padding:'5% 0 0'}}>
                <div id="land-left">
                    
                    {/* color:'#EAD3D1' */}
                    <h1 style={{marginBottom:0}}>
                        Let us help <span style={{color:'#5151CD',padding:"1% 2%", borderRadius:"49% 51% 60% 40% / 66% 40% 60% 34% ", border:"2px solid black"}}>hook</span> you with our services.
                    </h1>

                    <p style={{textAlign:'justify', color:'#fff',  marginBottom:'7%', fontSize:'small'}}>
                        At Macdasy, we are more than a web development company; we are your partners in digital success. 
                    </p>

                    <button 
                        onClick={scroll}
                        style={{ color:'#FCFCFF', padding:'0.5em 1em', marginBottom:'5%', width:'100%', fontSize:'1.5em', display:'inline-flex', gap:"10px", alignItems:'center', justifyContent:'space-between', cursor:'pointer' }}>
                        Get Started
                        <lord-icon
                            src="https://cdn.lordicon.com/jffauosv.json"
                            trigger="loop"
                            delay="2000"
                            colors="primary:#FCFCFF"
                            state="intro"
                            style={{width: '50px', height: '50px'}}>
                        </lord-icon>
                    </button>
                    <button 
                        onClick={scrollKnow}
                        className="lite" 
                        style={{ padding:'0.5em 1em', color: '#08081A', borderColor:'#08081A', width:'100%', fontSize:'1.5em', display:'inline-flex', gap:"10px", alignItems:'center', justifyContent:'space-between', cursor:'pointer' }}>
                            Experience Us!
                            <lord-icon
                                src="https://cdn.lordicon.com/kemgwlzl.json"
                                trigger="hover"
                                colors="primary:#08081A"
                                style={{width: '50px', height: '50px'}}>
                            </lord-icon>
                    </button>

                </div>
                <div id="land-right">
                    <div style={{background:'#9898F8', borderRadius:'15px', overflow:'hidden'}}>
                        <img 
                            src="https://cdn.dribbble.com/userupload/5505075/file/original-317a8441d729b3b49a52dc81c9e261e7.png?resize=1024x768" 
                            alt=''
                            style={{height:'100%', width:"80%"}}
                        />
                    </div>
                    <div style={{ background:'#FDFDFF', marginTop:'5%' ,padding:'3% 15%', borderRadius:'15px', display:'flex', justifyContent:'space-between', alignItems:'center' }}>
                        <h2 style={{color:'#070718', lineHeight:'0.9em', margin:0}}>   
                            10+ <br/>
                            <span style={{ textTransform:'uppercase', fontSize:'medium', color:'#6371A1'}}>Projects</span>
                        </h2>
                        <h2 style={{color:'#070718', lineHeight:'0.9em', margin:0}}>   
                            20+ <br/>
                            <span style={{ textTransform:'uppercase', fontSize:'medium', color:'#6371A1'}}> Services </span>
                        </h2>
                        <h2 style={{color:'#070718', lineHeight:'0.9em', margin:0}}>   
                            ~0 <br/>
                            <span style={{ textTransform:'uppercase', fontSize:'medium', color:'#6371A1'}}>Errors</span>
                        </h2>
                    </div>
                </div>
            </div>
        </section>
    )

}