import { useEffect } from 'react';
import $ from 'jquery';

const useScript = (url) => {
  useEffect(() => {

    // $(document).ready(function($) {
    //     $.scrollify({
            // section : ".scrolly",
    //     });
    // });

    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

export default useScript;
