
export default function Navbar(){

    const scroll = () => {
        document.querySelector( '#contact .lite' ).scrollIntoView({behavior:'smooth'})
    };

    return(
        <nav className="uk-navbar-container uk-navbar-transparent" style={{ padding:'3% 8%' }}>
            <div className="uk-container" style={{margin:0,padding:0, width:'100%', maxWidth:'100%'}}>
                <div data-uk-navbar>

                    <div className="uk-navbar-left">
                        <ul className="uk-navbar-nav">
                            <li className="uk-active">
                                <a href="." id="brand"> macdasy. </a>
                            </li>
                        </ul>
                    </div>

                    <div id="navbar-center">
                        <ul className="uk-navbar-nav">
                            <li className="uk-active"><a href="#approach"> Works </a></li>
                            <li className="uk-active"><a href="#services"> services </a></li>
                            <li className="uk-active"><a href="#projects"> Products </a></li>
                        </ul>
                    </div>

                    <div className="uk-navbar-right">
                        <ul className="uk-navbar-nav">
                            <li className="uk-active">
                                <button onClick={scroll}> Get Started </button>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </nav>
    )
}