// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Mousewheel } from 'swiper';

// Import Swiper styles
import 'swiper/css/pagination';
import Card from './Card';


// Images
import urja from './Assets/Pros/urja.png';
import sweetcups from './Assets/Pros/sweetcups.png';
import bg from './Assets/Pros/bg.png';
import fe from './Assets/Pros/fe.png';
import deltin from './Assets/Pros/deltin.jpg';
import gym from './Assets/Pros/gym.jpg';
import svs from './Assets/Pros/steels.jpg';
import party from './Assets/Pros/party.jpg';
import Sunny from './Assets/Pros/sunnysvilla.jpg';


export default function ProjectSwiper() {
  return (
    <>
      <Swiper
        mousewheel
        modules={[Mousewheel]}
        breakpoints={{
          500: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide>
         <Card title={'Urja'} img={urja} desc={ 'The Dying Studio' } link={'https://urja-thedyingstudio.netlify.app/'} web admin />
        </SwiperSlide>

        <SwiperSlide>
         <Card title={'Sweetcups'} img={sweetcups} desc={ 'A bakery shop' } link={'https://sweetcups.netlify.app/'}  web admin vr/>
        </SwiperSlide>

        <SwiperSlide>
         <Card title={'BudgetFare'} img={bg} desc={ 'Flight Prices Cutter' } link={'https://budgetfare.netlify.app/'} web admin  />
        </SwiperSlide>

        <SwiperSlide>
         <Card title={'FinEdge'} img={fe} desc={'An E-Financer'} link={'https://finedge-app.netlify.app/'}  web/>
        </SwiperSlide>

        <SwiperSlide>
         <Card title={'Deltin casino'} img={deltin} desc={'Deltin Casino Ticket Booker'} link={'https://dpt.adda52live.com/'}  web admin/>
        </SwiperSlide>

        <SwiperSlide>
         <Card title={'Fitness Diet Health'} img={gym} desc={'Fitness Advisor'} link={'https://fitnessdiethealth.netlify.app/'}  web admin/>
        </SwiperSlide>
        
        <SwiperSlide>
         <Card title={'SVS chemicals'} img={svs} desc={'Chemical Industry'} link={'https://svs.netlify.app/'}  web admin/>
        </SwiperSlide>
        
        <SwiperSlide>
         <Card title={'Tamizhar Needhi Party'} img={party} desc={'A Political Party'} link={'https://cocky-davinci-a02540.netlify.app/'}  web admin/>
        </SwiperSlide>
        
        <SwiperSlide>
         <Card title={'Sunnys villa'} img={Sunny} desc={'A Villa Booking App'} link={'https://sunnysvilla.netlify.app/'}  web admin/>
        </SwiperSlide>

      </Swiper>
    </>
  );
}
