import "./App.css";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import '../styles.scss';
import Landing from "../Landing";
import Services from "../Services";
import Approach from "./Approach";
import Projects from "../Projects";
import Testimonial from "../Testimonial";
import Contact from "../Contact";
import useScript from './UseScript';
import LandingPage from "./LandingPage";

function App() {
  useScript('https://code.jquery.com/jquery-1.7.1.min.js');

  return (
    <div className="App h-100">
        {/* <LandingPage /> */}
      <div id="nav-wrap" style={{height:'100%'}}>
        <Navbar />
        <Landing />
      </div>
      <Services />
      <Approach />
      <Projects />
      {/* <Testimonial /> */}
      <Contact />
    </div>
  );
}

export default App;
