import ProjectSwiper from "./Swiper";


export default function Projects(){

    return(
        <section id="projects" style={{ padding:'5% 0', background:'black' }}  className="scroll-wrap">

            <div style={{ padding:'0% 8%' }}>
                <button className="lite" style={{ color:'#9898F8', borderColor:"#9898F8",textTransform:'uppercase', display:'inline-flex', gap:"10px", alignItems:'center', maxWidth: 'max-content' }}>
                    <lord-icon
                        src="https://cdn.lordicon.com/ynwbvguu.json"
                        trigger="loop"
                        delay="2000"
                        colors="primary:#9898F8"
                        style={{width:'20px', height: '20px'}}>
                    </lord-icon>
                    Our Projects
                </button>

                <div data-uk-grid style={{padding:'5% 0%'}}>
                    <h2 style={{color:'white'}} className="uk-width-1-2@m"> We spend our time & brain to enlighten you! </h2>

                    <p className="uk-width-1-3@m" style={{fontSize:'0.9em'}}>
                        At Macdasy, we are more than a web development company; we are your partners in digital success. 
                    </p>

                    <div style={{ textAlign:'end' }} className="uk-width-1-6@m" id="down-arrow">
                            <button style={{ padding:'20%', background:"#9898F8" }}>
                                    <span uk-icon='arrow-down' />
                            </button>
                    </div>
                </div>
            </div>

            <div style={{   borderRadius:'25px 0px 0px 25px', 
                            overflow:'hidden', 
                            marginLeft:'8%', 
                            border: '1px solid grey'
                        }}>

                <ProjectSwiper />
            </div>

        </section>
    )

}