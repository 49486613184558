/* eslint-disable jsx-a11y/anchor-has-content */

export default function Contact(props){

    return(
        <div>
        <section id="contact" style={{ padding:'7% 8%',scrollSnapAlign: 'center'  }}  className="scroll-wrap-fit">

            <button className="lite" style={{ textTransform:'uppercase', display:'inline-flex', gap:"10px", alignItems:'center', maxWidth: 'max-content' }}>
                <lord-icon
                    src="https://cdn.lordicon.com/vpcmkqzt.json"
                    trigger="loop"
                    delay="2000"
                    colors="primary:#F3A777"
                    style={{width:'20px', height: '20px'}}>
                </lord-icon>
                Reach Us
            </button>

            <h2 style={{marginTop:'2%'}}> Let us work for you! </h2>

            <p className="uk-width-1-2@m">
                Contact us today to discuss your project, and let's embark on a journey to create a 
                remarkable digital experience for your audience.
            </p>

            <div data-uk-grid className="uk-grid-match">
                <div className="uk-text-left uk-width-expand@m">
                
                    <div style={{ padding:'8%', background:'#80808015', borderRadius:'20px', display:'flex', flexDirection:'column', justifyContent:'space-between' }}>

                        <p style={{ fontSize:'0.8em', marginBottom:'2%'  }}> <strong> FULL NAME </strong> </p>
                        <input placeholder="Type Your Name" />

                        <p style={{ fontSize:'0.8em', marginTop:'4%', marginBottom:'2%' }}> <strong> EMAIL ADDRESS </strong> </p>
                        <input placeholder="Type Your Email" />

                        <p style={{ fontSize:'0.8em', marginTop:'4%', marginBottom:'2%'  }}> <strong> YOUR MESSAGE </strong> </p>
                        <textarea placeholder="Type Your Name" />

                        <div style={{ width:'100%', textAlign:'right'}}>
                            <button style={{ background:'#F3A777', marginTop:'5%', display:'inline-flex', gap:"10px", justifyContent:'space-between', alignItems:'center', width: '40%' }}>
                                Submit
                                <span data-uk-icon='arrow-right' />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="uk-text-left uk-width-1-3@m">
                    <img 
                        style={{ borderRadius:'20px', overflow:'hidden' }}
                        src="https://cdn.dribbble.com/userupload/9160230/file/original-fa1611450dba6eee84ffb7ee4ee9f5ef.jpg?resize=1024x1452" 
                        alt="" 
                    />
                </div>
            </div>
        </section>

            <section style={{ marginTop:'5%', background:'black', padding:'5% 10% 1%'}} className="scroll-wrap-fit">

                <div data-uk-grid className="uk-grid-match" style={{ margin:0, alignItems:'start', paddingBottom:'5%', borderBottom:'1px solid grey' }}>
                    
                    <div style={{ textAlign:'left',padding:0 }} className="uk-width-expand@m">

                        <h1 style={{ color: "white", marginBottom:0 }}> macdasy. </h1>

                        <p style={{color:'#6371A1', opacity:"0.8", fontSize:'0.9em'}}>
                            At Macdasy, we are more than a web development company; <br/> 
                            we are your partners in digital success. 
                        </p>

                    </div>

                    <div style={{ textAlign:'left'}} className="uk-width-1-3@m">
                        <div class="uk-child-width-expand@s" data-uk-grid>
                            
                            <div>
                                <p style={{color:'#6371A1', fontWeight:'900'}}> COMPANY </p>
                                <ul class="uk-list">
                                    <li> <a href='.'>Blogs</a> </li>
                                    <li> <a href='.'>Community</a> </li>
                                    <li> <a href='.'>Pricing</a> </li>
                                </ul>
                            </div>

                            <div>
                                <p style={{color:'#6371A1', fontWeight:'900'}}>LINKS</p>
                                <ul class="uk-list">
                                    <li> <a href='#services'>Services</a> </li>
                                    <li> <a href='#approach'>Approach</a> </li>
                                    <li> <a href='#testimonial'>Testimonials</a> </li>
                                    <li> <a href='#contact'>Contact</a> </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>

                <div style={{ display:'flex', justifyContent:'space-between', alignItems:'end', padding:'3% 0' }}>
                    
                    <p style={{fontSize:'small', margin:0, color:"white"}}> &#169; Macdasy inc. All rights reserved. </p>
                    
                    <div>
                        <a href="." class="uk-icon-button uk-margin-small-right" uk-icon="instagram" />
                        <a href="." class="uk-icon-button  uk-margin-small-right" uk-icon="linkedin" />
                        <a href="." class="uk-icon-button" uk-icon="youtube" />
                    </div>
                </div>

            </section>
        </div>
    )

}