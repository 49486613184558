
export default function Approach(){

    return(
        <section id='approach' style={{padding:'5% 8%'}}  className="scroll-wrap">
           
            <div className="uk-child-width-1-2@m" data-uk-grid>

                <div style={{ display:'flex', flexDirection:'column'}}>
                    <img 
                        style={{ borderRadius:'15px', overflow:'hidden' }}
                        src="https://cdn.dribbble.com/userupload/4756131/file/original-3c98a9b0b5fd5fa29f487a111c4c0703.png?resize=1024x1024" alt="" />
                </div>

                <div style={{ display:'flex', flexDirection:'column'}}>
            
                    <button className="lite" style={{ display:'inline-flex', gap:"10px", alignItems:'center', maxWidth: 'max-content' }}>
                            <lord-icon
                                src="https://cdn.lordicon.com/vufjamqa.json"
                                trigger="loop"
                                delay="2000"
                                colors="primary:#70314F"
                                style={{width:'20px', height: '20px'}}>
                            </lord-icon>
                            Our Approach
                    </button>

                    <h2 style={{marginTop:'3%'}}>
                        Our Unique Approach!
                    </h2>

                    <div>
                       

                        <div style={{ display:'flex', gap:'20px', alignItems:'baseline', marginBottom:'5%'  }}>
                            <span uk-icon="icon: pencil; ratio:2"></span>
                            <div>
                                <p style={{marginBottom:'1%', color:'black'}}> <strong> Design </strong> </p>
                                <p style={{margin:0}}> Our creative mock-ups could embody your brand's personality while focusing on usability and engagement. </p>
                            </div>
                        </div>

                        <div style={{ display:'flex', gap:'20px', alignItems:'baseline', marginBottom:'5%' }}>
                            <span uk-icon="icon: git-branch; ratio:2"></span>
                            <div>
                                <p style={{marginBottom:'1%', color:'black'}}> <strong> Development </strong> </p>
                                <p style={{margin:0}}> Using the latest coding performance, we bring the design to life with functionality and interactivity that enhance the user experience.  </p>
                            </div>
                        </div>

                        <div style={{ display:'flex', gap:'20px', alignItems:'baseline', marginBottom:'5%' }}>
                            <span uk-icon="icon: settings; ratio:2"></span>
                            <div>
                                <p style={{marginBottom:'1%', color:'black'}}> <strong> Testing </strong> </p>
                                <p style={{margin:0}}>Rigorous testing ensures that your website functions seamlessly across browsers, devices, and user scenarios. </p>
                            </div>
                        </div>

                        <div style={{ display:'flex', gap:'20px', alignItems:'baseline', marginBottom:'5%' }}>
                            <span uk-icon="icon: database; ratio:2"></span>
                            <div>
                                <p style={{marginBottom:'1%', color:'black'}}> <strong> Optimization </strong> </p>
                                <p style={{margin:0}}> We continuously monitor and optimize your site's performance to ensure it delivers exceptional results. </p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </section>
    )

} 