export default function Card(props){

    function redirect(e){ 
            e.stopPropagation();
            window.open(e.target.ariaLabel) 
    }

    return(
        <div className="card" style={{ 
                                    padding:'10% 10% 7%', 
                                    height:'300px', 
                                    textAlign:'left', 
                                    display:'flex', 
                                    flexDirection:'column', 
                                    justifyContent:'space-between',
                                    borderRight:'1px solid grey',
                                    cursor: 'pointer',
                                    position:'relative',
                                    overflow:'hidden',
                                    zIndex:99
                                }}
                                title={props.title}
                                aria-label={props.link}
                                onClick={redirect}
                                >

            <img
                title={props.title}
                aria-label={props.link}
                onClick={redirect}
                src={props.img}
                alt=""
                style={{ position:'absolute', right:50, top:100, zIndex:1, opacity:0.7, width:'70%', transition:'all 0.7s' }}
            />

            <p 
                title={props.title}
                aria-label={props.link}
                onClick={redirect}
                id='title'
                style={{fontSize:'1.5em',margin:0, textTransform:'uppercase'}}
            ><strong
                title={props.title}
                aria-label={props.link}
                onClick={redirect}
            > {props.title} </strong>
            </p>

            <div style={{position:'relative'}}
                title={props.title}
                aria-label={props.link}
                onClick={redirect}
            >
                <p 
                    title={props.title}
                    aria-label={props.link}
                    onClick={redirect}
                    style={{margin:0}}> <strong title={props.title}
                    aria-label={props.link}
                    onClick={redirect} > {props.desc} </strong> </p>
                <p 
                title={props.title}
                aria-label={props.link}
                onClick={redirect}
                id="type"
                style={{margin:0}}> <small title={props.title}
                aria-label={props.link}
                onClick={redirect}> WEBSITE { (props.admin) && '| ADMIN '} {props.vr && '| VR Site'  } </small> </p>

            <button className="lite" 
                    id="card-btn"
                    title={props.title}
                    aria-label={props.link}
                    onClick={redirect}>
                    <span title={props.title}
                    aria-label={props.link}
                    onClick={redirect} data-uk-icon='icon: arrow-right' />
            </button>

            {/* <button className="lite" 
                    title={props.title}
                    aria-label={props.link}
                    onClick={redirect}
                    style={{ cursor:'pointer',width:"100%",marginTop:"5%",display:'inline-flex', gap:"10px", alignItems:'center', justifyContent:'space-between' }}>
                   View More
                    <span data-uk-icon='icon: arrow-right' />
            </button> */}
            </div>

        </div>
    )

}